.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 13px;
  border-radius: 8px;
  font-family: var(--font-bold);
  font-style: normal;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  border: none;
  height: 37px;
  width: 100%;
}

.disabled {
  opacity: 0.35;
  cursor: auto;
}

.small {
  border-radius: 8px;
  height: 25px;
  font-size: 14px;
}

.interaction {
  background: var(--interaction);
  color: #fff;
}

.accent {
  background: #6DA544;
  color: #fff;
}

.invert {
  color: var(--interaction);
  background: transparent;
  border: 1px solid var(--interaction);
}

.invertLight {
  color: #757575;
  background: transparent;
  border: 1px solid #757575;
}

.warning {
  color: #fff;
  background: #eb3737;
}

.fixedSize {
  min-width: 88px;
}

.tag {
  color: #000;
  font-size: 16px;
  font-family: var(--font-normal);
  font-style: normal;
  font-weight: 400;
  padding: 6px 16px;
}

.postMessage {

  @media only screen and (max-height: 449px),
  (max-width: 599px) {
    color: #206AFE;
    background: none;
    height: 20px;
  }
}