.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px 0;
}

.loader {
  fill: transparent;
  stroke: var(--interaction);
  stroke-width: 4;
  animation: dash 1.6s ease infinite, rotate 1.6s linear infinite;
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 95;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -4;
  }

  100% {
    stroke-dasharray: 85, 95;
    stroke-dashoffset: -98;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.testinam {
  width: 100%;
}