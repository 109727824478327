.error {
    color: #D3172D;
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    padding: 0;

    ul {
        list-style: none;
    }
}