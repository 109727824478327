.wrapper {
    width:100%;
    position: relative;
    input {
        padding-left: 30px;
        background: #fff url('./images/search.svg') 8px center no-repeat;
        border-radius: 0px;
        width: 100%;
        height: 40px;
        border: none;
        font-size: 16px;
        line-height: 19px;
        color: #434242;
        font-family: var(--font-base);
    }
    .label {
        font-size: 12px;
        color: var(--page-text);
        margin-bottom: 4px;
    }
}