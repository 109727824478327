.crest {
    width: 100%;
    padding-left: 0px;
    position: relative;
    @media screen and (min-width: 1081px) {
        width: calc(543px * var(--scale));
        padding-left: calc(80px * var(--scale));
    }
    .svg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media screen and (min-width: 1081px) {
            justify-content: flex-start;
        }
    }
}

.img {
    width: calc(395px * var(--scale));
    @media screen and (min-width: 1081px) {
        width: calc(395px * var(--scale));
    }
}

.content {
    position: absolute;
    top: calc(76px * var(--scale));
    @media screen and (min-width: 1081px) {
        left: calc(82px * var(--scale));
    }
}

.overall {
    display: flex;
    width: calc(395px * var(--scale));
    height: calc(140px * var(--scale));
    justify-content: center;
    align-items: center;

}

.pts {
    color: var(--badge-text-top);
    text-align: center;
    font-size: calc(117px * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: calc(20px * var(--scale));

    &.noClubCountry {
        margin-right: 0;
    }
}

.wrapFlags {
    display: flex;
    flex-direction: column;
    width: calc(70px * var(--scale));
    max-width: calc(160px * var(--scale));
    min-height: calc(144px * var(--scale));
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-shrink: 0;
    flex-wrap: wrap;
}

.name {
    display: flex;
    width: calc(388px * var(--scale));
    height: calc(60px * var(--scale));
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    color: var(--badge-text-top);
    text-align: center;
    text-overflow: ellipsis;
    font-size: calc(40px * var(--scale));
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.performance {
    display: flex;
    padding: 0px calc(16px * var(--scale));
    align-items: flex-start;
    margin-top: calc(12px * var(--scale));
    justify-content: center;
}

.scoreItem {
    display: flex;
    width: calc(56px * var(--scale));
    flex-direction: column;
    align-items: center;
    margin-right: calc(4px * var(--scale));
}

.scoreName {
    color: var(--badge-text-bottom);
    text-align: center;
    font-size: calc(30px * var(--scale));
    font-style: normal;
    font-weight: 600;
    line-height: calc(44px * var(--scale));
    text-transform: uppercase;
    opacity: 0.5;
}

.scoreVal {
    color: var(--badge-text-bottom);
    text-align: center;
    font-size: calc(44px * var(--scale));
    font-style: normal;
    font-weight: 700;
    line-height: calc(56px * var(--scale));
}

.country {
    margin-bottom: calc(20px * var(--scale));

    img {
        border-radius: calc(1.6px * var(--scale));
        border: calc(1.6px * var(--scale)) solid var(--badge-text-top);
    }
}

.club {
    img {
        height: 54px;
    }
}