:root {

  --interaction: #5BA07C;
  --page-text: #6e7e6a;
  --page-header: rgba(0, 165, 118, 0.20);
  --page-background: #F7F4EB;
  --page-background-image: '';
  --table-text: #000;
  --table-text-accent: #5BA07C;
  --table-background: #fff;
  --badge-text-top: #000;
  --badge-text-bottom: #000;
  --scale: 0.7;

  @media (min-height: 1000px) {
    --scale: 1;
  }

  @media (min-height: 2160px) {
    --scale: 2;
  }
  @media screen and (max-width: 767px) {
    --scale: 0.7;
  }
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {

  max-width: 100vw;
  overflow-x: hidden;
  --font-base: "Antonio", sans-serif;
  font-family: var(--font-base);
  background-color: var(--page-background);
  background-image: var(--page-background-image, 'none');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
  position: relative;
}


a {
  color: inherit;
  text-decoration: none;
}

ul,
li {
  margin: 0px;
  padding: 0px;
  list-style: none;
}