.main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    flex-direction: column;
    min-height: 500px;
    @media screen and (min-width: 1081px) {
        min-height: calc(100vh - calc(308px* var(--scale)));
    }

    .input {
        height: 90px;
        width: 200px;
        font-size: 44px;
        margin-bottom: 30px;
        @media screen and (min-width: 768px) {
            width: 200px;
        }
    }

    .initForm {
        position: relative;
    }

    .error {
        position: absolute;
        bottom: 45px;
        width: 100vw;
    }
}