.results {
    max-height: 200px;
    position: absolute;
    overflow: auto;
    min-width: 300px;
    width: 100%;
    background-color: #fff;
    z-index: 2;
    top: 58px;
    border: 1px solid #8B8B8B;
    cursor: pointer;
    &:empty{
        border: none;
    }
}
.item {
    min-height: 35px;
    padding: 3px 15px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #757575;
    &:last-child{
        border-bottom: none;
    }
    .name { 
        font-size: 16px;
        line-height: 19px;
        color:#000;
            font-family: var(--font-base);
    }
}